import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { ContractService } from '@wlp/ui-bs-signup';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-contract',
  templateUrl: './contract.page.html',
  styleUrls: ['./contract.page.scss'],
})
export class ContractPage {
  brand: string;
  wlName: string;
  company: any;
  contractForm: FormGroup;
  isDisabled: boolean = true;
  navigationBackground: string;
  agreementAccepted: boolean = false;
  public backButtonValue: boolean;

  constructor(
    private navCtrl: NavController,
    private contractService: ContractService,
    private layoutConfigService: LayoutConfigService,
    private loadingService: LoadingService,
    private alert: AlertController
  ) {
    this.backButtonValue = false;
    this.isDisabled = false;
    this.agreementAccepted = false;
    this.contractForm = this.contractService.getContractForm();
    this.contractService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.company = this.layoutConfigService.getConfig('wlTheme.company');
  }

  continue() {
    this.loadingService.show();
    this.contractService
      .sendContract(this.contractForm)
      .subscribe((data) => {
        if(data.status != undefined) {
          this.loadingService.hide();
          this.navCtrl.navigateRoot('status-response', {
            state: {
              status: data.status,
            },
          });
        }else if(data != undefined) {
          this.loadingService.hide(); 
        }
        console.log(data);
      }, (error) => {
        console.log(error);
        this.alert.create({
          header: 'Error',
          message: 'Ocorreu um erro ao tentar executar sua solicitação. Por favor, tente novamente ou entre em contato com nossa equipe. ' + error,
          buttons: ['OK']
        });
        this.loadingService.hide();
      }
    );
  }

  acceptContract(event) {
    this.isDisabled = event.target.checked;
  }

  goPrivacyPolicy() {
    this.navCtrl.navigateRoot('privacy-policy');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }
}
